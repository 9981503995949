import styles from './projectssection.module.scss';

const ProjectsSection = (props) => {  
  return (
    <section id="projects-section-root" className={styles.projectsSectionRoot}>
      <div className={styles.sectionContainer}>
        <div className={styles.projectsGrid}>
          <div className={styles.projectItem}>project 1</div>
          <div className={styles.projectItem}>project 2</div>
          <div className={styles.projectItem}>project 3</div>
          <div className={styles.projectItem}>project 4</div>
          <div className={styles.projectItem}>project 5</div>
          <div className={styles.projectItem}>project 6</div>
          <div className={styles.projectItem}>project 7</div>
          <div className={styles.projectItem}>project 8</div>
          <div className={styles.projectItem}>project 9</div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;