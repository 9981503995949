import { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Link, animateScroll as scroll } from "react-scroll";
//import ScrollTrigger from "gsap/ScrollTrigger";

import styles from './headernav.module.scss';

const HeaderNav = (props) => { 

  useLayoutEffect(() => {

    const a1 = gsap.fromTo(
      ".navParent", 
      { opacity: 0, x:200 }, 
      { opacity: 1, x:0, duration: .8, repeat: 0, delay: .8 });

    console.log(`Header.useEffect [] - animate title`);

    // Cleanup
    return () => {
      a1.kill();
    };
  }, []);

  const handleSetActive = () => {
    //console.log("HeaderNav.handleSetActive - scroll to top");
    // gsap.fromTo(
    //   ".navParent", 
    //   { opacity: 0, x:200 }, 
    //   { opacity: 1, x:0, duration: .8, repeat: 0, delay: 0 });
  }

  return (
    <nav  className="navParent">
      <ul>
        <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="top-of-page" spy={true} smooth={true} offset={0} duration={500} onSetActive={handleSetActive}>home</Link>
        <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="profile-section-root" spy={true} smooth={true} offset={0} duration={500}>profile</Link>
        <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="experiences-section-root" spy={true} smooth={true} offset={0} duration={500}>experiences</Link>
        <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="abilities-section-root" spy={true} smooth={true} offset={0} duration={500}>abilities</Link>
        <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="projects-section-root" spy={true} smooth={true} offset={0} duration={500}>projects</Link>
        <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="#" spy={true} smooth={true} offset={0} duration={500}>contact</Link>
      </ul>
    </nav>
  )
};

export default HeaderNav;