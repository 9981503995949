import { useContext, useEffect} from 'react';
import { Link } from "react-router-dom";
import netlifyIdentity from 'netlify-identity-widget';
import IdentityContext from '../../utils/context';

import styles from './navlinks.module.scss';

const NavLinks = (props) => {

  const [isAuth, setIsAuth, name, setName, userId, setUserId] = useContext(IdentityContext);

  useEffect(() => {  
    console.log(`Header.useEffect []`);  
    netlifyIdentity.on('init', user => console.log('netlifyIdentity.init'));
    netlifyIdentity.on('error', err => console.error('netlifyIdentity.error', err));
    netlifyIdentity.on('open', () => console.log('netlifyIdentity.open'));
    netlifyIdentity.on('close', () => console.log('netlifyIdentity.close'));
    netlifyIdentity.on('login', user => {
      console.log(`netlifyIdentity.login`);
      const fn = user.user_metadata.full_name.split(' ', 2)[0];
      setIsAuth(true);
      setUserId(user.id);
      setName(fn);
    });
    netlifyIdentity.on('logout', () => {
      console.log(`netlifyIdentity.logout`)
      setIsAuth(false);
      setUserId("");
      setName("");
    });
  }, [setIsAuth, setName, setUserId]);


  const loginHandler = () => {
    netlifyIdentity.open();
  }

  const logoutHandler = () => {
    netlifyIdentity.logout();
  }

  const isAdminLinksAvailable = isAuth && userId === process.env.REACT_APP_NETLIFY_IDENTITY_ID;

  const adminLinks = (
    <>
      <Link to="/dashboard" className={styles.buttonToLink}>dashboard</Link><span className={styles.linkSeparator}>|</span>
      <Link to="/" className={styles.buttonToLink}>bio</Link><span className={styles.linkSeparator}>|</span>
    </>
  );

  const isLoggedInUi = (
    <div className={`${styles.linkContainer} navLinks`}>
      <div>Hi {name}!</div><span className={styles.linkSeparator}>|</span>  
      {isAdminLinksAvailable ? adminLinks : null}
      <button className={styles.buttonToLink} onClick={logoutHandler}>logout</button>
    </div>
  );

  const loggedOutUi = (
    <div className={`${styles.linkContainer} navLinks`}>
      <button className={styles.buttonToLink} onClick={loginHandler}>login</button>
    </div>
  );

  // conditionally show link items
  // https://stackoverflow.com/questions/59866628/react-js-how-can-i-show-hide-some-parts-of-component-based-on-url-path
  return (
    <div className={styles.navLinksRoot}>
      {isAuth ? isLoggedInUi : loggedOutUi}
    </div>
  );
};

export default NavLinks;