import styles from './profilesection.module.scss';

const ProfileSection = (props) => {  
  return (
    // <div className={styles.profileSectionRoot}>Profile Section</div>
    <section id="profile-section-root" className={styles.profileSectionRoot}>
      <div className={`${styles.gridItem} ${styles.header1}`}>profile</div>
      <div className={`${styles.gridItem} ${styles.header2}`}>.NET solutions architect and full-stack developer</div>
      <div className={`${styles.gridItem} ${styles.colLeft} ${styles.bioImg}`}></div>
      <div className={`${styles.gridItem} ${styles.colRight}`}>
        <h1>Hello</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat libero quis libero vestibulum suscipit. Sed vitae libero euismod, laoreet velit sit amet, ullamcorper quam.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat libero quis libero vestibulum suscipit. Sed vitae libero euismod, laoreet velit sit amet, ullamcorper quam.</p>            
      </div>
      <div className={`${styles.gridItem} ${styles.footer}`}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat libero quis libero vestibulum suscipit. Sed vitae libero euismod, laoreet velit sit amet, ullamcorper quam.
      </div>
    </section>
  )
};

export default ProfileSection;