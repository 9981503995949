/* eslint-disable no-lone-blocks */
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import netlifyIdentity from 'netlify-identity-widget';
import IdentityContext from '../../utils/context';
import ProtectedRoute from "../../components/ProtectedRoute";
import BioView from '../BioView';
import DashboardView from '../DashboardView';
import styles from './app.module.scss';

const App = (props) => {

  const user = netlifyIdentity.currentUser();
  const userName = (user && user.user_metadata) ? user.user_metadata.full_name : '';

  const [name, setName] = useState(userName);

  // PROD
  const [isAuth, setIsAuth] = useState((user) ? true : false);
  const [userId, setUserId] = useState('');

  // DEV
  // const [isAuth, setIsAuth] = useState(true);
  // const [userId, setUserId] = useState(process.env.REACT_APP_NETLIFY_IDENTITY_ID);  

  //console.log(`App.render - env: ${process.env.NODE_ENV}, REACT_APP_TEST: ${process.env.REACT_APP_TEST}`);

  useEffect(() => {
  }, []);


  return (
    <div className={styles.appRoot}>    
      <IdentityContext.Provider value={[isAuth, setIsAuth, name, setName, userId, setUserId]}>  
        <Routes>
          <Route exact path="/" element={<BioView />} />
          <Route exact path="/dashboard"
            element={
              <ProtectedRoute redirectTo="/">
                <DashboardView/>
              </ProtectedRoute>
            }/>
        </Routes>
      </IdentityContext.Provider>
    </div>    
  );
}

export default App;

{/* <Route exact path='/dashboard' element={<ProtectedRoute/>}>
<Route exact path='/dashboard' element={<DashboardView/>}/>
</Route> */}

{/* <IdentityContext.Provider value={[isAuth, setIsAuth]}>

</IdentityContext.Provider> */}
{/* <Route exact path='/' element={<BioView />} /> */}
{/* <Route path="/dashboard" element={<DashboardView />} /> */}
{/* <Route exact path='/dashboard' element={<ProtectedRoute/>}>
  <Route exact path='/dashboard' element={<DashboardView/>}/>
</Route> */}

//import logo from '../../assets/images/logo.svg';
//import './app.css';

// function App() {
//   return (
//     <div className={styles.App}>
//       <header className={styles.AppHeader}>
//         <img src={logo} className={styles.AppLogo} alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className={styles.AppLink}
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }


  // const initNetlifyIdentity = () => {

  //   window.netlifyIdentity = netlifyIdentity;
  //   netlifyIdentity.init();


  //   // if (identity) {
  //   //   identity.init(); 
  //   //   const user = identity.currentUser();  
  //   //   console.log(`App.initNetlifyIdentity - identity: ${identity}, user: ${JSON.stringify(user)}`);
  //   // } else {
  //   //   console.log(`App.initNetlifyIdentity - missing from window`);
  //   // }
  // }
  // const netlifyAuth = {
  //   isAuthenticated: false,
  //   user: null,
  //   authenticate(callback) {
  //     this.isAuthenticated = true;
  //     netlifyIdentity.open();
  //     netlifyIdentity.on('login', user => {
  //       this.user = user;
  //       callback(user);
  //     });
  //   },
  //   signout(callback) {
  //     this.isAuthenticated = false;
  //     netlifyIdentity.logout();
  //     netlifyIdentity.on('logout', () => {
  //       this.user = null;
  //       callback();
  //     });
  //   }
  // };