import React, { useEffect, useState, useContext } from "react";
import coinGecko from '../../../apis/coinGecko';
import { WatchListContext } from '../../../context/watchListContext';
import Coin from '../Coin';

import styles from './coinlist.module.scss';

const CoinList = () => {

  const [coins, setCoins] = useState([]);
  const { watchList, deleteCoin } = useContext(WatchListContext);
  const [isLoading, setIsLoading] = useState(false);

  console.log(`CoinList.render - coin_count: ${coins.length}, watchlist_count: ${watchList.length}`);

  useEffect(() => {

    const fetchData = async () => {
      
      // Loading
      setIsLoading(true);   
      
      // Async get
      const response = await coinGecko.get(
        "/coins/markets/", 
        { params: { vs_currency: "usd", ids: watchList.join(",") }});

      // Update binding collection
      setCoins(response.data);

      // Stop loading
      setIsLoading(false);
    };

    // If we have items in watchlist, fetch data, otherwise clear out binding collection
    if (watchList.length > 0) {
      fetchData();
    } else {
      setCoins([]);
    }

    // Clean-up
    return () => {}
  }, [watchList]);

  const renderCoins = () => {
    
    if (isLoading) return <div>Loading...</div>;

    return (
      <div className={styles.watchListWidget}>
        <h2>Watchlist via CoinGecko</h2>
        <ul className={styles.ul}>
          {coins.map((coin) => {
            return <Coin key={coin.id} coin={coin} deleteCoin={deleteCoin} />;
          })}
        </ul>
      </div>
    );
  };

  return (<div>{renderCoins()}</div>);
};

export default CoinList;