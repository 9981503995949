import styles from './abilityitem.module.scss';

const AbilityItem = (props) => {  
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.abilityItemRoot}> 
        <div className={`${styles.gridItem} ${styles.category}`}>.NET skills</div>
        <div className={`${styles.gridItem} ${styles.skillList}`}>C#, JavaScript, WPF, ASP.NET, ASP.NET Core, MVC, MVVM, React.JS, Unity, Electron, SQL, DB, AWS, VB, Windows Mobile</div>
      </div>
    </div>
  );
};

export default AbilityItem;