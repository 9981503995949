import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import IdentityContext from '../../utils/context';

const ProtectedRoute = ({ children, redirectTo }) => {  
  const [isAuth, setIsAuth, name, setName, userId, setUserId] = useContext(IdentityContext);
  const isAllowed = isAuth && userId === process.env.REACT_APP_NETLIFY_IDENTITY_ID;
  return isAllowed ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;


// import { Navigate, Outlet  } from 'react-router-dom';

// const ProtectedRoute = () => {
//   const isAuth = false;
//   return isAuth ? <Outlet/> : <Navigate to='/'/>;
// };

// export default ProtectedRoute;