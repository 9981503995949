import styles from './experienceitem.module.scss';

const ExperienceItem = (props) => {  
  return (
    <section className={styles.experienceItemRoot}>
      <div className={`${styles.gridItem} ${styles.name}`}>University of Washington</div>
      <div className={`${styles.gridItem} ${styles.title}`}>Bachelor Computer Science</div>
      <div className={`${styles.gridItem} ${styles.date}`}><p>1998-2002</p></div>
      <div className={`${styles.gridItem} ${styles.description}`}>        
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis feugiat libero quis libero vestibulum suscipit. Sed vitae libero euismod, laoreet velit sit amet, ullamcorper quam.</p>
        <p>some more stuff</p>
      </div>
    </section>
  );
};

export default ExperienceItem;