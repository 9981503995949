import React from 'react';

const CoinDetail = ({ id }) => {
  
  
  return (
    <div>
      
    </div>
  );
}

export default CoinDetail;

