import { useEffect, useState, useRef, useCallback } from "react";
//import * as Web3Client from '../../utils/Web3Client';
import NavLinks from '../../components/NavLinks';
import CryptoWatchlistWidget from "../../components/DashboardView/CryptoWatchlistWidget";

import styles from './dashboardview.module.scss';

const DashboardView = (props) => {
  return (    
    <div className={styles.dashboardRoot}>
      <div className={styles.headerArea}>
        <NavLinks/>
      </div>
      <div className={styles.contentArea}>
        <CryptoWatchlistWidget/>
      </div>
    </div>
  );
}

export default DashboardView;

// Get currency pairs - /products


// const [currencies, setCurrencies] = useState([]);
// const [pair, setPair] = useState('');
// const [price, setPrice] = useState('0.00');
// const [pastData, setPastData] = useState({});

// const [products, setProducts] = useState([]);

// const ws = useRef(null);
// let first = useRef(false);

      //fetchProducts();

      //ws.current = new WebSocket('wss://ws-feed.pro.coinbase.com');      
      //Web3Client.init();

  // api pattern
  //https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/


  // if you want async method outside of useeffect
  // const fetchProducts = useCallback(async () => {
  //   let response = await fetch(`${COINBASE_PRO_URL}/products`);
  //   response = await response.json();
  //   console.log(`api.fetchProducts - ${JSON.stringify(response)}`);
  //   setProducts(response);
  // }, [setProducts])

  // const getProducts = async () => {
  //   let result = [];
  //   await fetch(`${COINBASE_PRO_URL}/products`)
  //     .then((response) => response.json())
  //     .then((data) => result = data);
      
  //   //.error((err) => console.log(`api - error: ${err}`));
  //   return result;             
  // };

//   <div className={styles.coinbaseWidgetRoot}>
//   <div className={styles.entry}>
//     <h2>Coinbase Pro</h2>
//     <form className="Form" onSubmit={handleSubmit}>
//       <input
//         type="text"
//         autoFocus
//         autoComplete="off"
//         name="search"
//         placeholder="Search Coinbase Pro API"/>
//       <button> Search </button>
//     </form>
//   </div>
//   <div className={styles.status}>
//     {status === 'idle' && (<div>Status: idle</div>)}
//     {status === 'error' && <div>Error: {error}</div>}
//     {status === 'fetching' && <div className="loading">Status: loading...</div>}
//   </div>
//   <div className={styles.results}>
//     {status === 'fetched' && (
//       <>
//         <div className="query"> Search results for {query} </div>
//         {items.length === 0 && <div> No data found! </div>}
//         {items.length > 0 && (
//           <>
//             <div>Item count: {items.length}</div>
//             <div>Filtered Item count: {filtered.length}</div>
//           </>
//         )}

//       </>
//     )}
//   </div>
// </div>


// const COINBASE_PRO_URL = 'https://api.pro.coinbase.com';

// 	const [query, setQuery] = useState('');

// 	const url = query && `${COINBASE_PRO_URL}${query}`;

// 	const { status, data, error } = useFetch(url);

//   const handleSubmit = (e) => {
// 		e.preventDefault();
//     setQuery('/products');

// 	};


//   // Full dataset of currency pairs
//   const items = data;

//   // Filter USD
//   let filtered = items.filter((item) => {
//     if (item.quote_currency === "USD") return item;
//   });

//   // Sort alphabetically
//   filtered = filtered.sort((a,b) => {
//     if (a.base_currency < b.base_currency) return -1;
//   });



//import { useFetch } from '../../utils/useFetch';