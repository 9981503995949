import React from 'react';
import CoinList from '../CoinList';
import CoinDetail from '../CoinDetail';

import { WatchListContextProvider } from '../../../context/watchListContext';

const CryptoWatchlistWidget = () => {
  
  return (    
    <div>
      <WatchListContextProvider>
        <CoinList/>
        <CoinDetail/>
      </WatchListContextProvider>
    </div>
  );
}

export default CryptoWatchlistWidget;
