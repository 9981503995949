import React from "react";
import { Link } from "react-router-dom";

import styles from './coin.module.scss';

const Coin = ({ coin, deleteCoin }) => {

  const handleDeleteCoin = (e) => {
    e.preventDefault();
    deleteCoin(coin.id)
  };

  //to={`/coins/${coin.id}`}
  return (
    <Link to={`/dashboard`} className={styles.coinLink}>
      <li className={`${styles.coinlistItem} coinlistItem`}>
        
        {/* Icon */}
        <img className={styles.coinlistImage} src={coin.image} alt="" />

        {/* Market price */}
        <span className={styles.coinPrice}>{coin.current_price}</span>

        {/* 24h % chg */}
        <span
          className={coin.price_change_percentage_24h < 0 ? styles.danger : styles.success}>
          {" "}
          {coin.price_change_percentage_24h < 0 
            ? (<i className="fas fa-sort-down"></i>) 
            : (<i className="fas fa-sort-up"></i>)
          }
          {coin.price_change_percentage_24h}
        </span>

        {/* Hover delete button - remove item from watchlist */}
        <i 
          onClick={(e) => handleDeleteCoin(e)}
          className={`${styles.deleteIcon} far fa-times-circle`}>
        </i>

      </li>
    </Link>
  );
};

export default Coin;