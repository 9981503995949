import AbilityItem from '../AbilityItem';
import styles from './abilitiessection.module.scss';

const AbilitiesSection = (props) => {  
  return (
    <section id="abilities-section-root" className={styles.abilitiesSectionRoot}>
      <div className={styles.sectionContainer}>
        <AbilityItem></AbilityItem>
        {/* <div className="horizontal-divider"></div> */}
        <AbilityItem></AbilityItem>
        {/* <div className="horizontal-divider"></div> */}
        <AbilityItem></AbilityItem>
      </div>
    </section>
  );
};

export default AbilitiesSection;