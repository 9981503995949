import { useRef, useLayoutEffect } from "react";
import NavLinks from '../NavLinks';
import { gsap } from "gsap";
import styles from './header.module.scss';

const Header = (props) => {

  const titleRef = useRef();
  const q = gsap.utils.selector(titleRef);

  // Note use of useLayoutEffect hook for opacity fades
  useLayoutEffect(() => {

    const a1 = gsap.fromTo(
      q(".titleChar"), 
      { opacity: 0 }, 
      { opacity: 1, duration: .01, stagger: .05, repeat: 0, delay: 1 });

    const a2 = gsap.fromTo(
      ".titleParent", 
      { opacity: 0, x:-200 }, 
      { opacity: 1, x:0, duration: 1, repeat: 0, delay: .8 });

    const a3 = gsap.fromTo(
      ".navLinks", 
      { opacity: 0, }, 
      { opacity: 1, duration: .5, repeat: 0, delay: 1.5 });

    //console.log(`Header.useEffect [] - animate title`);

    // Cleanup
    return () => {
      a1.kill();
      a2.kill();
      a3.kill();
      // animation2.scrollTrigger.kill();
      // window.removeEventListener("pointermove", onMove);
    };
  }, [q]);

  return (
    <header id="top-of-page" className={styles.headerRoot}>
      <div className={styles.areaLeft}>
        <div className={`${styles.titleParent} titleParent`} ref={titleRef}>
          <div className={`${styles.titleChar} titleChar`}>b</div>
          <div className={`${styles.titleChar} titleChar`}>r</div>
          <div className={`${styles.titleChar} titleChar`}>i</div>
          <div className={`${styles.titleChar} titleChar`}>a</div>
          <div className={`${styles.titleChar} titleChar`}>n</div>
          <div className={`${styles.titleChar} titleChar`}>&nbsp;</div>
          <div className={`${styles.titleChar} titleChar`}>h</div>
          <div className={`${styles.titleChar} titleChar`}>a</div>
          <div className={`${styles.titleChar} titleChar`}>m</div>          
          <div className={`${styles.titleChar} titleChar`}>p</div>
          <div className={`${styles.titleChar} titleChar`}>s</div>
          <div className={`${styles.titleChar} titleChar`}>o</div>
          <div className={`${styles.titleChar} titleChar`}>n</div>
        </div>  
      </div>
      <div className={styles.areaMiddle}></div>
      <div className={styles.areaRight}>
        <NavLinks />
      </div>
    </header>
  );
};

export default Header;


    // gsap.fromTo(
    //   titleRef, 
    //   { opacity: 0 }, 
    //   { opacity: 1, duration: .8, repeat: 0, delay: 1 });

/* eslint-disable no-lone-blocks */
// 
// import { TweenMax, TimelineMax } from 'gsap';
// import ScrollTrigger from "gsap/ScrollTrigger";

{/* ref={e => this.headerContainer = e } */}
{/* <div className={styles.nameContainer} > */}
{/* <div ref={e => this.headerElements[0] = e}>b</div>
<div ref={e => this.headerElements[1] = e}>r</div>
<div ref={e => this.headerElements[2] = e}>i</div>
<div ref={e => this.headerElements[3] = e}>a</div>
<div ref={e => this.headerElements[4] = e}>n</div>
<div>&nbsp;</div>
<div ref={e => this.headerElements[5] = e}>h</div>
<div ref={e => this.headerElements[6] = e}>a</div>
<div ref={e => this.headerElements[7] = e}>m</div>
<div ref={e => this.headerElements[8] = e}>p</div>
<div ref={e => this.headerElements[9] = e}>s</div>
<div ref={e => this.headerElements[10] = e}>o</div>
<div ref={e => this.headerElements[11] = e}>n</div> */}
{/* </div>
<div className={styles.logoContainer}>
<img src={AspReactLogo} alt="clear" width={80} />
</div> */}