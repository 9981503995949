import { useLayoutEffect } from "react";
import { Parallax, Background } from 'react-parallax';
import { gsap } from "gsap";

import Header from '../../components/Header';
import HeaderNav from '../../components/HeaderNav';
import ProfileSection from '../../components/BioView/ProfileSection';
import ExperienceSection from '../../components/BioView/ExperienceSection';
import AbilitiesSection from '../../components/BioView/AbilitiesSection';
import ProjectsSection from '../../components/BioView/ProjectsSection';
import PulsingButton from "../../components/PulsingButton";
import ParticlesAttract from '../../components/ParticlesAttract';

import styles from './bioview.module.scss';
//import Img1 from '../../assets/images/overhead_surf_1.jpg';
import Img2 from '../../assets/images/waves-rock.jpg';
import Img3 from '../../assets/images/math.jpg';
import Img4 from '../../assets/images/black_tile.jpg';
import Img5 from '../../assets/images/waves-2.png';
import Img6 from '../../assets/images/black-cubes.jpg';

const BioView = (props) => {

  useLayoutEffect(() => {

    const a1 = gsap.fromTo(
      ".parallaxHomeRoot", 
      { opacity: 0 }, 
      { opacity: 1, duration: 1.5, repeat: 0, delay: .4 });

    const a2 = gsap.fromTo(
      ".hero", 
      { opacity: 0 }, 
      { opacity: 1, duration: .5, repeat: 0, delay: 1.5 });

    //console.log(`BioView.useLayoutEffect [] - animate bg`);

    // Cleanup
    return () => {
      a1.kill();
      a2.kill();
    };
  }, []);

  return (
    <div className={styles.bioRoot}>
      <Header/>
      <HeaderNav/>
      <Parallax
        className="parallaxHomeRoot"
        blur={0}
        bgImage={Img6}
        bgImageAlt="wave"
        strength={600}>
        <div className={`${styles.parallaxHomeRoot}`}>
          <div className={`${styles.hero} hero`}>
            {/* <div className={styles.particleContainer}> */}
              <ParticlesAttract/>
            {/* </div>             */}
            <PulsingButton/>
          </div>
          <div className={styles.parallaxNavHeader}>profile</div>
        </div>  
      </Parallax>
      <ProfileSection/>
      <Parallax
        blur={{ min: -1, max: 3 }}
        bgImage={Img5}
        bgImageAlt="wave"
        strength={100}>
        <div className={styles.parallaxSmallRoot}>
          <div className={styles.parallaxNavHeader}>experiences</div>
        </div>
      </Parallax>
      <ExperienceSection/>
      <Parallax
        blur={{ min: -1, max: 2 }}
        bgImage={Img3}
        bgImageAlt="wave"
        strength={300}>
        <div className={styles.parallaxSmallRoot}>
          <div className={styles.parallaxNavHeader}>abilities</div>
        </div>
      </Parallax>
      <AbilitiesSection/>
      <Parallax
        blur={{ min: -1, max: 2 }}
        bgImage={Img4}
        bgImageAlt="wave"
        strength={100}>
        <div className={styles.parallaxSmallRoot}>
          <div className={styles.parallaxNavHeader}>projects</div>
        </div>
      </Parallax>
      <ProjectsSection/>
      <Parallax
        blur={{ min: -1, max: 3 }}
        bgImage={Img2}
        bgImageAlt="wave"
        strength={100}>
        <div className={styles.parallaxSmallRoot}>
          <div className={styles.parallaxNavHeader}>contact</div>
        </div>
      </Parallax>
    </div>
  );
}

export default BioView;