import ExperienceItem from '../ExperienceItem';
import styles from './experiencesection.module.scss';

const ExperienceSection = (props) => {  
  return (
    <section id="experiences-section-root" className={styles.experiencesSectionRoot}>
      <div className={styles.sectionContainer}>
        <h1>employment</h1>
        <ExperienceItem></ExperienceItem>
        <ExperienceItem></ExperienceItem>
        <ExperienceItem></ExperienceItem>
        <hr></hr>
        <h1>education</h1>
        <ExperienceItem></ExperienceItem>
        <ExperienceItem></ExperienceItem>
        <ExperienceItem></ExperienceItem>
      </div>
    </section>
  );
};

export default ExperienceSection;