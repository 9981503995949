import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

import styles from './pulsingbutton.module.scss';

const PulsingButton = (props) => {
  return (
    <div className={styles.outerContainer}>
      <Link className={styles.defaultLink} activeClass={styles.defaultLinkActive} to="profile-section-root" spy={true} smooth={true} offset={0} duration={500}>
        <div className={styles.circle}></div>
          <div className={styles.buttonContainer}>
            <div className={styles.chevron}></div>
            <div className={styles.chevron}></div>
            <div className={styles.chevron}></div>
          </div>
        
      </Link>
    </div>
  );
}

export default PulsingButton;
